<template>
  <div class="flex space-between m-b-sm">
    <h1 class="page-heading">{{ $t("event.vehicle_information") }}</h1>
  </div>

  <div class="flex space-between m-b-sm">
    <span class="vrm bold number-plate-gbr" style="font-size: 1.3rem; cursor: pointer;" @click.stop.prevent="copyToClipboard(vehicle.registration)">
      {{ vehicle.registration }}
    </span>
    <!-- TODO - Why add to blacklist when vehicle is alredy blacklisted -->
    <button
      class="btn btn-primary"
      v-if="blacklist.national.by.length > 0"
      @click="() => unblacklistModal.show()"
    >
      {{ $t("blacklist.remove_from_blacklist") }}
    </button>
    <!-- <button
      class="btn btn-primary"
      v-else
      @click="() => addToBlacklistModal.show()"
    >
      {{ $t("blacklist.add_to_blacklist") }}
    </button> -->
  </div>

  <div class="row" v-if="!loading">
    <div v-if="blacklist.national.by.length > 0" class="col-xl-12">
      <div class="card blacklist-container">
        <div class="card-body">
          <strong>
            {{ $t("vehicle.blacklisted", { text: "By" }).toUpperCase() }}
          </strong>
          <p
            v-for="elem in blacklist.national.by"
            :key="elem.index"
            style="margin: 0"
          >
            {{ elem.name }} for <strong> {{ elem.reason }} </strong>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xl-6">
      <div class="card alert alert-custom alert-indicator-top indicator-info">
        <div class="card-title fn-weight-500">
          {{ $t("global.claim", { text: "Overview" }) }}
        </div>
        <div v-if="loading" class="parent-div">
          <div
            class="spinner-border center-div"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
        <div v-else class="card-body grid" style="padding: 10px">
          <div class="text-muted">{{ $t("event.claims_outstanding") }}</div>
          <div class="">{{ blacklist.local.claims }}</div>
          <div class="text-muted">{{ $t("event.money_owed") }}</div>
          <div class="">£{{ blacklist.local.value }}</div>
        </div>
        <div v-show="!loading" class="view-claims-btn" @click="viewClaims">
          <span> {{ $t("navigation.side.view_claims") }} </span>
        </div>
      </div>
    </div>

    <div class="col-xl-6">
      <div
        class="card alert alert-custom alert-indicator-top indicator-warning"
      >
        <div class="card-title fn-weight-500">
          {{ $t("event.vehicle_information") }}
        </div>
        <div v-if="loading" class="parent-div">
          <div
            class="spinner-border center-div"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
        <div v-else class="card-body grid" style="padding: 10px">
          <div class="text-muted">{{ $t("global.make") }}</div>
          <div class="">{{ mmc.make }}</div>
          <div class="text-muted">{{ $t("global.model") }}</div>
          <div class="">{{ mmc.model }}</div>
          <div class="text-muted">{{ $t("global.colour") }}</div>
          <div>{{ mmc.colour }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <!-- Notes -->
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title fn-weight-500">{{ $t("event.notes") }}</h5>
          <div v-if="loading" class="parent-div">
            <div
              class="spinner-border center-div"
              role="status"
              style="margin-right: 5px"
            >
              <span class="visually-hidden">{{ $t("global.loading") }}...</span>
            </div>
          </div>
          <div
            v-if="!loading"
            id="card-content"
            style="min-height: 331px"
            ref="notes_container"
          >
            <p v-if="vehicle.notes.length < 1" class="no-notes">
              {{ $t("event.no_notes") }}
            </p>
            <div
              v-else
              v-for="note in vehicle.notes"
              :key="note.index"
              class="msg-wrapper m-b-xs"
            >
              <span> {{ note.note }} </span>
              <div class="note-timestamp m-t-xxs">
                <Timestamp :time="note.created_at" />
                <span> by {{ note.user.email }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer" id="vehicle">
          <input
            type="text"
            v-model="newNote"
            placeholder="Write your message here ..."
            id="newNote"
            @keydown.enter="addNewNote"
          />
          <i
            v-if="!addingNewNote"
            class="material-icons send-icon"
            title="Press Enter to send"
            @click="addNewNote"
          >
            send
          </i>
          <div
            v-else
            class="spinner-border"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Customer Service Message -->
    <div class="col-xl-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title fn-weight-500">
            {{ $t("event.customer_service_message") }}
          </h5>
          <MessageCard
            alertType="indicator-info"
            :alertTitle="$t('claims.new.comments')"
            :alertText="$t('event.csm_info')"
            id="commentcard"
          />
          <MessageCard
            alertType="indicator-danger"
            :alertTitle="$t('claims.new.very_important')"
            :alertText="$t('event.csm_danger')"
            id="dangercard"
          />
          <p
            v-if="vehicle.customerServiceMessage.message?.message == undefined"
            class="no-notes"
          >
            {{ $t("event.no_csm") }}
          </p>
          <div v-else class="msg-wrapper m-b-xs">
            <span style = "word-break: break-word;"> {{ vehicle.customerServiceMessage.message.message }} </span>
            <div class="note-timestamp m-t-xxs">{{ csm_timestamp }}</div>
          </div>
        </div>
        <div class="card-footer" id="vehicle">
          <input
            type="text"
            v-model="newCSM"
            placeholder="Write your message here ..."
            id="newNote"
            @keydown.enter="updateCustomerServiceMessage"
          />
          <i
            v-if="!updatingCSM"
            class="material-icons send-icon"
            title="Press Enter to send"
            @click="updateCustomerServiceMessage"
          >
            send
          </i>
          <div
            v-else
            class="spinner-border"
            role="status"
            style="margin-right: 5px"
          >
            <span class="visually-hidden">{{ $t("global.loading") }}...</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Add to Blacklist modal -->
  <div
    class="modal fade"
    id="addToBlacklistModal"
    tabindex="-1"
    aria-labelledby="addToBlacklistTitle"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center">
            {{ $t("blacklist.add_to_blacklist") }}
          </h4>
          <p class="modal-sub-title">
            {{ $t("blacklist.blacklist_modal_message") }}
            {{ currentEvent?.vehicle.plate }}
          </p>
          <select class="form-select m-b-md" v-model="reason">
            <option value="" selected>Select A Reason</option>
            <option
              v-for="reason in blacklistReasons"
              :value="reason"
              :key="reason"
            >
              {{ reason }}
            </option>
          </select>
          <div class="flex-center">
            <button
              class="btn btn-secondary m-r-sm"
              @click="() => addToBlacklistModal.hide()"
            >
              {{ $t("global.close") }}
            </button>
            <button class="btn btn-primary" @click="addToBlacklist">
              {{ $t("blacklist.add_to_blacklist") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Remove from Blacklist Modal -->
  <div
    class="modal fade"
    id="unblacklistModal"
    tabindex="-1"
    aria-labelledby="unblacklistModal"
    style="display: none"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-h-xxl p-v-xxl">
        <div class="modal-title">
          <h4 class="m-r-sm textAlign-center">
            {{ $t("blacklist.remove_from_blacklist") }}
          </h4>
        </div>
        <div class="flex p-v-sm" style="flex-wrap: nowrap">
          <i class="material-icons warning-icon m-r-sm"> warning_amber </i>
          <span>
            {{ $t("blacklist.are_you_sure") }} ?
            {{ $t("blacklist.modal_message") }}
          </span>
        </div>
        <div class="flex-center">
          <button
            class="btn btn-secondary m-r-sm"
            @click="() => unblacklistModal.hide()"
          >
            {{ $t("global.cancel") }}
          </button>
          <button class="btn btn-primary" @click="unblacklist">
            {{ $t("blacklist.remove_from_blacklist") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vehicleService from "../../services/vehicle.service";
import Timestamp from "../../components/Timestamp.vue";
import MessageCard from "../../components/MessageCard.vue";
import { DateTime } from "luxon";
import externalService from "../../services/external.service";

export default {
  name: "Vehicle",
  components: {
    Timestamp,
    MessageCard,
  },
  data() {
    return {
      vehicle: {
        id: -1,
        registration: this.$route.params.registration,
        notes: [],
        customerServiceMessage: {
          message: {},
        },
        claims: [],
        eventCount: -1,
      },
      blacklist: {
        local: {},
        national: {
          by: [],
        },
      },
      selectedClient: this.$store.state.auth.user.selectedClient,
      loading: false,
      newNote: "",
      addingNewNote: false,
      newCSM: "",
      updatingCSM: false,
      mmc: {
        make: "",
        model: "",
        colour: "",
      },
      unblacklistModal: null,
      addToBlacklistModal: null,
      blacklistReasons: [
        "Drive Off",
        "No Means of Payment",
        "Fly Tipping",
        "Theft",
        "Aggressive",
        "Plate Mismatch",
      ],
    };
  },
  mounted() {
    this.loadVehicleData();
    this.unblacklistModal = new bootstrap.Modal(
      document.getElementById("unblacklistModal")
    );
    this.addToBlacklistModal = new bootstrap.Modal(
      document.getElementById("addToBlacklistModal")
    );
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$success(`Copied ${text} to clipboard`);
        })
        .catch((error) => {
          console.error("Clipboard copy failed", error);
          this.$error("Failed to copy to clipboard");
        });
    },
    loadVehicleData() {
      this.loading = true;
      vehicleService
        .getVehicle(this.selectedClient, this.$route.params.registration)
        .then((response) => {
          this.vehicle.id = response.data.id;
          this.blacklist.local = response.data.blacklist.local;
          this.blacklist.national = response.data.blacklist.national;
          this.vehicle.eventCount = response.data.eventCount;
          this.vehicle.claims = response.data.claims;
          this.getVehicleNotes();
          this.getCustomerServiceMessage();
          this.fetchMMC();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getVehicleNotes() {
      vehicleService
        .getVehicleNotes(this.selectedClient, this.vehicle.id)
        .then((response) => {
          this.vehicle.notes = response.data.notes.reverse();
          this.$refs.notes_container.scrollTop =
            this.$refs.notes_container.scrollHeight;
        })
        .catch((error) => {
          console.log("Failed to load vehicle notes.", error);
        });
    },
    //TODO - Not working, error 404
    getCustomerServiceMessage() {
      vehicleService
        .getCustomerServiceMessage(this.selectedClient, this.vehicle.id)
        .then((response) => {
          this.vehicle.customerServiceMessage.message = response.data.message;
        })
        .catch((error) => {
          console.log("Failed to load customer service message.", error);
        });
    },
    addNewNote() {
      this.addingNewNote = true;
      if (this.newNote.length <= 1) {
        this.$error("event.valid_note");
        this.newNote = "";
        this.addingNewNote = false;
        return;
      }
      vehicleService
        .putVehicleNote(this.selectedClient, this.vehicle.id, this.newNote)
        .then((response) => {
          this.newNote = "";
          this.addingNewNote = false;
          this.getVehicleNotes();
        })
        .catch((error) => {
          this.error(this.$t("vehicle_notes.error_msg"), error);
          this.addingNewNote = false;
          this.newNote = "";
        });
    },
    updateCustomerServiceMessage() {
      this.updatingCSM = true;
      if (this.newCSM.length <= 1) {
        this.$error(this.$t("event.valid_note"));
        this.newCSM = "";
        this.updatingCSM = false;
        return;
      }
      vehicleService
        .putCustomerServiceMessage(
          this.selectedClient,
          this.vehicle.id,
          this.newCSM
        )
        .then((response) => {
          this.vehicle.customerServiceMessage.message.message = this.newCSM;
          this.newCSM = "";
          this.updatingCSM = false;
          this.$success(
            this.$t("csm.success_msg") + " " + this.vehicle.registration
          );
        })
        .catch((error) => {
          this.$error(this.$t("csm.error_msg"), error);
          this.updatingCSM = false;
          this.newCSM = "";
        });
    },
    fetchMMC() {
      externalService
        .getMMC(this.vehicle.registration)
        .then((response) => {
          this.mmc.make = response.data.vehicle.make;
          this.mmc.model = response.data.vehicle.model;
          this.mmc.colour = response.data.vehicle.colour;
        })
        .catch((error) => {
          console.log("Failed to fetch mmc.", error);
        });
    },
    viewClaims() {
      this.$router.push({
        path: "/reports/claims",
      });
    },
    unblacklist() {
      vehicleService
        .unblacklistVehicle(this.selectedClient, this.vehicle.registration)
        .then((response) => {
          this.$success(this.$t("blacklist.unblacklist_success"));
        })
        .catch((error) => {
          this.$error(this.$t("blacklist.unblacklist_error"), error);
        })
        .finally(() => {
          this.unblacklistModal.hide();
        });
    },
  },
  computed: {
    csm_timestamp: function () {
      if (this.vehicle.customerServiceMessage.message.created_at == null)
        return "";
      return DateTime.fromFormat(
        `${this.vehicle.customerServiceMessage.message.created_at}`,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      ).toFormat("dd/MM/yyyy HH:mm:ss");
    },
  },
};
</script>

<style scoped>
#card-content {
  overflow-y: scroll;
  max-height: 300px;
}

.no-notes {
  font-weight: 100;
  font-style: italic;
}

#newNote {
  border: none;
  width: 92%;
}

#newNote:focus {
  outline: none;
}

.send-icon {
  vertical-align: middle;
  /* color: var(--font-color-secondary); */
  position: relative;
}

/* .send-icon:hover {
        color: white;
        cursor: pointer;
    } */

.msg-wrapper {
  width: fit-content;
  padding: 10px 25px;
  /* background-color: #2E2E3F; */
  border-radius: 10px;
  overflow: hidden;
}

.note-timestamp {
  font-size: 11px;
}

.blacklist-container {
  background-color: var(--utility-red);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 1.5rem;
}

.claim-container {
  background-color: var(--utility-blue);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 1.5rem;
}

.grid {
  display: grid;
  grid-template-columns: 50% 50%;
}

.grid div {
  margin-bottom: 10px;
}

.view-claims-btn {
  border-radius: 5px;
  padding: 5px 10px;
  text-align: center;
}

.warning-icon {
  font-size: 50px;
  color: var(--utility-orange);
}
</style>
